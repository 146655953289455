<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="mt-2">
    <template v-if="showPlayer">
      <v-expand-transition>
        <record-player
          @closed="closedPlayer"
          v-if="showPlayer || (recordList.length > 0 && showPlayer)"
          :file="file"
        />
      </v-expand-transition>

      <v-divider />
    </template>
    <template v-if="from != 'Lead'">
      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-autocomplete
            v-model="reqbody.employeeUuid"
            class="mx-2"
            label="Search by Employees"
            :items="workersf"
            :loading="loadingEmployees"
            :filter="
              (item, queryText) => {
                return item.fullname
                  .toLowerCase()
                  .includes(queryText.toLowerCase());
              }
            "
            item-text="fullname"
            item-value="uuid"
            autocomplete="off"
            clearable
            @change="getRecords"
            hide-details
            outlined
            dense
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.fullname"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-if="data.item.ocupation"
                  class="text-capitalize"
                  v-html="data.item.ocupation.name"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="data.item.phone"
                  class="text-capitalize"
                  v-html="data.item.phone"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="">
          <ma-date-picker v-model="dateFrom" label="From" past />
        </v-col>
        <v-col class="mx-2">
          <ma-date-picker v-model="dateTo" label="To" past />
        </v-col>
        <template v-if="validClean">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-2"
                elevation="0"
                small
                rounded
                depressed
                fab
                v-on="on"
                v-bind="attrs"
                @click="_clearFilters"
              >
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </template>
            <span>Clear filters</span>
          </v-tooltip>
        </template>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="recorded"
        :footer-props="footerProps"
        :options.sync="options"
        :server-items-length="totalrecord"
        item-key="uuid"
        :loading="loadingR"
      >
        <template v-slot:[`item.duration`]="{ item }">
          <div class="text-body-2">{{ prettyTime(item.duration) }}</div>
        </template>
        <template v-slot:[`item.createAt`]="{ item }">
          <div class="text-body-2">{{ formatDate(item.createAt) }}</div>
        </template>
        <template v-slot:[`item.from`]="{ item }">
          <template v-if="item.inbound == 1 || item.inbound == true">
            {{ item.to }}
          </template>
          <template v-else>
            {{ item.from }}
          </template>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ from == "Lead" ? item.employee.fullname : item.employee_fullname }}
        </template>
        <template v-slot:[`item.origin`]="{ item }">
          <template v-if="item.inbound == 1 || item.inbound == true">{{
            from
          }}</template>
          <template v-else> Employee </template>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          {{/* Play record */}}
          <div class="d-flex justify-end">
            <template v-if="item.timeMarcInit && item.timeMarcEnd">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="loadingaudio"
                    :loading="loadingaudio && currentAudio == item && part"
                    color="secondary"
                    icon
                    @click="playRecordNocomplete(item)"
                  >
                    <v-icon small>mdi-play-circle</v-icon>
                  </v-btn>
                </template>
                <span>Play Recorded Call Mark</span>
              </v-tooltip>
            </template>
            <template>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="loadingaudio"
                    :loading="loadingaudio && currentAudio == item && !part"
                    color="primary"
                    icon
                    @click="playRecord(item)"
                  >
                    <v-icon small>mdi-play-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Play Recorded Call</span>
              </v-tooltip>
            </template>

            {{/* Remove record */}}
            <template v-if="admin">
              <v-btn color="red" icon @click="setRecordToRemove(item)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </div>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      <!--   <v-text-field
        prepend-inner-icon="mdi-magnify"
        label="Search"
        outlined
        clearable
        single-line
        hide-details
        dense
        v-model="search"
      ></v-text-field> -->
      <v-data-table
        :headers="headers"
        :items="recorded"
        :footer-props="footerProps"
        :search="search"
        item-key="uuid"
        :loading="loadingR"
      >
        <template v-slot:[`item.duration`]="{ item }">
          <div class="text-body-2">{{ prettyTime(item.duration) }}</div>
        </template>
        <template v-slot:[`item.createAt`]="{ item }">
          <div class="text-body-2">{{ formatDate(item.createAt) }}</div>
        </template>
        <template v-slot:[`item.from`]="{ item }">
          <template v-if="item.inbound == 1 || item.inbound == true">
            <div class="d-flex">
              <div class="cursor-pointer" @click="makeCallV(item.to)">
                {{ item.to }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="copyPhone(item.to)"
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Phone</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex">
              <div class="cursor-pointer" @click="makeCallV(item.from)">
                {{ item.from }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="copyPhone(item.from)"
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Phone</span>
                </v-tooltip>
              </div>
            </div>
          </template>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.employee ? item.employee.fullname : "-" }}
        </template>
        <template v-slot:[`item.origin`]="{ item }">
          <template v-if="item.inbound == 1 || item.inbound == true">{{
            from
          }}</template>
          <template v-else> Employee </template>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          {{/* Play record */}}
          <div class="d-flex justify-end">
            <template v-if="item.timeMarcInit && item.timeMarcEnd">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="loadingaudio"
                    :loading="loadingaudio && currentAudio == item && part"
                    color="secondary"
                    icon
                    @click="playRecordNocomplete(item)"
                  >
                    <v-icon small>mdi-play-circle</v-icon>
                  </v-btn>
                </template>
                <span>Play Recorded Call Mark</span>
              </v-tooltip>
            </template>
            <template v-if="item.recordUrl && item.duration != 0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :disabled="loadingaudio"
                    :loading="loadingaudio && currentAudio == item && !part"
                    color="primary"
                    icon
                    @click="playRecord(item)"
                  >
                    <v-icon small>mdi-play-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Play Recorded Call</span>
              </v-tooltip>
            </template>

            {{/* Remove record */}}
            <template v-if="admin">
              <v-btn color="red" icon @click="setRecordToRemove(item)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </div>
        </template>
      </v-data-table>
    </template>
    <template v-if="from != 'Lead'">
      <all-images
        :uuid="uuid"
        :imagesProps="pictures"
        :isConsent="true"
        :imagesfrom="from == 'Lead' ? 'lead' : 'patient'"
        @uploaded="upDateView"
      />
    </template>

    {{/* Confirm remove */}}
    <v-dialog
      v-model="dialogConfirmRemove"
      max-width="280"
      content-class="rounded-xl"
      :persistent="loadingR"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-regular rounded-xl">
          {{ $t("dialogTitle") }}
        </v-card-title>
        <v-card-text class="text-center"> Are you sure ? </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            :disabled="loadingR"
            @click="cancelRemove"
            text
          >
            {{ $t("labelCancel") }}
          </v-btn>
          <v-btn color="red" :loading="loadingR" @click="remove" text>
            {{ $t("labelRemove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import cleanObject from "@/utils/cleanObject";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { CallList } from "@/mixins";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";
import { apiBaseUrl } from "@/enviorment";
import RecordPlayer from "@/views/CallCenter/RecordPlayer.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import AllImages from "@/components/Images/AllImages.vue";
import { funtionsPhone } from "@/utils/mixins";
export default {
  name: "recorded-calls-cl",
  mixins: [CallList, funtionsPhone],
  props: {
    from: {
      type: String,
      default: () => "Lead",
    },
    uuid: {
      type: String,
      default: () => "",
    },
    calls: {
      type: Array,
      default: () => [],
    },
    pictures: {
      type: Array,
      default: () => [],
    },
    phone: String,
  },
  components: {
    RecordPlayer,
    MaDatePicker,
    AllImages,
  },
  data() {
    return {
      admin: false,
      search: "",
      loadingR: false,
      showPlayer: false,
      dialogConfirmRemove: false,
      recorded: [],
      totalrecord: 0,
      options: {},
      footerProps: { "items-per-page-options": [15, 50, 100, 1000] },
      headers: [
        {
          text: this.$t("Origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("Phone"),
          align: "start",
          sortable: false,
          value: "from",
        },

        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      filterRange: "year",
      dateFrom: "",
      dateTo: "",
      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        record: true,
        dateRange: {
          date1: "",
          date2: "",
        },
        phoneNumber: null,
        employeeUuid: null,
      },

      file: "",
      loadingaudio: false,
      currentAudio: "",
      part: false,
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getRecords();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRange = "custom";
        this.getRecords();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRange = "custom";
        this.getRecords();
      }
    },
  },
  computed: {
    ...mapGetters(["isSuper", "isAdmin", "getpatientN", "getUuid"]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    workersf() {
      const w = this.employees.filter((e) => e.phone != null && e.phone != "");
      return w;
    },
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmCallCenterModule", ["actRemoveRecordedCall"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    getRecords() {
      this.recorded = this.calls;
      this.totalrecord = this.calls.length;
      /* if (this.from == "Lead") {
        this.recorded = this.calls;
        this.totalrecord = this.calls.length;
      } else {
        this.loadingR = true;
        const { page, itemsPerPage } = this.options;
        const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
        this.reqbody.range = {
          limit: itmper,
          offset: (page - 1) * itmper,
        };
        if (this.filterRange == "year") {
          this.reqbody.dateRange = {
            date1: moment().startOf("year").utc().toISOString(),
            date2: moment().utc().toISOString(),
          };
        } else {
          this.reqbody.dateRange = {
            date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
            date2: moment(this.dateTo).endOf("day").utc().toISOString(),
          };
        }

        let body = Object.assign({}, this.reqbody);
        body.phoneNumber = this.phone;
        if (!this.admin) {
          body.employeeUuid = this.getUuid;
        }

        body = cleanObject(body);
        getAPI
          .post("/twiliocloud/getregisteredcalls", body)
          .then((res) => {
            this.recorded = res.data.result;
            this.totalrecord = res.data.count;
            this.loadingR = false;
          })
          .catch((error) => {
            this.loadingR = false;
            let mess = error.response.data.message.toString();
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } */
    },

    async playRecordNocomplete(file) {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "audio/mpeg",
            };
      this.loadingaudio = true;
      this.currentAudio = file;
      this.part = true;
      const url = apiBaseUrl + "/zoom/getCallAudio/" + file.uuid + "/" + false;
      try {
        const response = await axios({
          method: "get",
          url: url,
          responseType: "blob",
          headers: auth,
        });

        const buffer = new Blob([response.data]);

        let file_path = URL.createObjectURL(buffer);
        this.file = file_path;
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
        this.showPlayer = true;
      } catch (error) {
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
      }
    },
    async playRecord(file) {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "audio/mpeg",
            };
      this.loadingaudio = true;
      this.part = false;
      this.currentAudio = file;
      const url = apiBaseUrl + "/zoom/getCallAudio/" + file.uuid + "/" + true;
      try {
        const response = await axios({
          method: "get",
          url: url,
          responseType: "blob",
          headers: auth,
        });

        const buffer = new Blob([response.data]);

        let file_path = URL.createObjectURL(buffer);
        this.file = file_path;
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
        this.showPlayer = true;
      } catch (error) {
        this.loadingaudio = false;
        this.currentAudio = null;
        this.part = false;
      }
    },
    closedPlayer() {
      this.file = "";
      this.showPlayer = false;
    },
    setRecordToRemove(record) {
      this.currentAudio = record;
      this.dialogConfirmRemove = true;
      this.showPlayer = false;
    },
    cancelRemove() {
      this.currentAudio = "";
      this.dialogConfirmRemove = false;
      this.showPlayer = false;
    },
    _clearFilters() {
      this.dateFrom = "";
      this.dateTo = "";
      this.filterRange = "year";
      this.getRecords();
    },

    upDateView() {
      this.$emit("uploaded");
    },

    remove() {
      this.actRemoveRecordedCall(this.currentAudio.uuid).then(() => {
        this.dialogConfirmRemove = false;
        this.getRecords();
        const en = this.$i18n.locale == "en";
        const bodyEn = "Call deleted successfully";
        const bodyEs = "Llamada eliminada con éxito";

        notifyInfo(en ? bodyEn : bodyEs);
      });
    },
  },
  mounted() {
    if (this.from != "Lead") {
      if (this.isAdmin || this.isSuper) {
        this.admin = true;
        this.actGetEmployees();
      }
    } else {
      this.getRecords();
    }
  },
};
</script>
<style lang="scss" scoped></style>
<i18n>
{
  "en": {
    "dialogTitle": "Delete recording?",
    "origin":"Origin"
  },
  "es": {
    "dialogTitle": "¿Eliminar grabación?",
    "origin":"Origen"
  }
}
</i18n>
